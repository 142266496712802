.sliding-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: -webkit-fill-available;
  background: #040b14;
  transition: all 0.3s ease;
  width: 60px;
  color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-shadow: 2px 0 8px rgba(0,0,0,0.1);
}

.sliding-panel.expanded {
  width: 300px;
}

.panel-toggle {
  position: fixed;
  left: 40px;
  top: 8px;
  width: 30px;
  height: 30px;
  background: #149ddd;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sliding-panel.expanded .panel-toggle {
  left: 285px;
}

.panel-content {
  position: relative;
  width: 60px;
  transition: all 0.3s ease;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #149ddd #040b14;
}

.sliding-panel.expanded .panel-content {
  width: 300px;
}

.panel-sections {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile {
  text-align: center;
  padding: 30px 0 0 0;
}

.sliding-panel:not(.expanded) .profile {
  padding: 40px 0;
  position: relative;
  z-index: 1002;
}

.sliding-panel:not(.expanded) .profile-image-container {
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  margin: 8px auto;
  cursor: pointer;  
}

.sliding-panel:not(.expanded) .profile-image-container img,
.sliding-panel:not(.expanded) .profile-tooltip,
.sliding-panel:not(.expanded) h1,
.sliding-panel:not(.expanded) .profile > *:not(.social-links):not(.profile-image-container) {
  display: none;
}

.sliding-panel:not(.expanded) .profile-image-container::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: var(--favicon-url) center/contain;
  border-radius: 8px;
}

.favicon {
  width: 32px;
  height: 32px;
  margin: 8px auto;
}

.profile img:not(.favicon) {
  width: 120px;
  margin: 15px auto;
}

.profile-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(20, 157, 221, 0.95);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  width: max-content;
  max-width: 200px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, visibility 0.3s;
  text-align: center;
}

.profile img:hover + .profile-tooltip,
.profile-tooltip:hover {
  visibility: visible;
  opacity: 1;
}

.social-links {
  position: relative;
  z-index: 1002;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 4px;
}

/* Add vertical layout when panel is collapsed */
.sliding-panel:not(.expanded) .social-links {
  flex-direction: column;
  align-items: center;
  gap: 8px;  /* Slightly larger gap for vertical layout */
}

.social-links a,
.adventure-link {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  text-decoration: none;
}

/* Ensure icons are centered within their containers */
.social-links a i,
.adventure-link i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.social-links a:hover {
  background: #149ddd;
  color: #fff;
  text-decoration: none;
}

.adventure-link:hover {
  background: #149ddd;
}

.nav-menu {
  position: relative;
  z-index: 1002;
  padding: 10px 0;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
}

.nav-link {
  position: relative;
  z-index: 1002;
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  color: #a8a9b4;
  padding: 12px 15px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  text-align: left;
  cursor: pointer;
}

.sliding-panel:not(.expanded) .nav-link {
  justify-content: center;
  padding: 12px 0;
}

.nav-link i {
  font-size: 24px;
  padding-right: 8px;
  color: #6f7180;
}

.sliding-panel:not(.expanded) .nav-link i {
  padding-right: 0;
}

.nav-link:hover {
  color: #fff;
}

.nav-link:hover i {
  color: #149ddd;
}

.nav-text {
  display: none;
}

.sliding-panel.expanded .nav-text {
  display: inline;
}

.menu-footer {
  padding: 20px 15px;
}

.menu-footer h3 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.menu-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-footer ul li {
  padding: 5px 0;
}

.menu-footer ul li a {
  color: #a8a9b4;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.menu-footer ul li a:hover {
  color: #fff;
}

.current-work {
  margin-bottom: 20px;
}

.collapsible-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.section-header {
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
  padding: 6px 8px;
  text-align: center !important;
  border-top: none;
  border-bottom: none;
  position: relative;
  z-index: 2;
  font-size: 13px;
}

.sliding-panel:not(.expanded) .section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.section-icon {
  font-size: 16px;
  color: #149ddd;
  transition: color 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-header:hover .section-icon {
  color: #149ddd;
}

.sliding-panel.expanded .section-icon {
  display: none;
}

.sliding-panel:not(.expanded) .section-text {
  display: none;
}

.section-content {
  height: auto;
  opacity: 1;
  overflow: hidden;
  padding: 15px 0 0 0;
  margin: 0;
}

.activity-tag {
  cursor: pointer;
  margin-bottom: 10px;
}

.activity-content {
  display: flex;
  align-items: center;
  padding: 8px 15px;
}

.activity-label {
  margin-left: 10px;
  font-weight: bold;
}

.activity-description {
  margin-left: 10px;
  font-size: 12px;
  color: #a8a9b4;
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  pointer-events: none;
  left: 0;
  right: 0;
}

.carousel-control {
  background: rgba(20, 157, 221, 0.1);
  border: none;
  color: #149ddd;
  cursor: pointer;
  padding: 6px;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  z-index: 5;
}

.carousel-control:hover {
  background: rgba(20, 157, 221, 0.2);
  color: #fff;
}

.carousel-control.left {
  left: 5px;
  position: absolute;
}

.carousel-control.right {
  right: 5px;
  position: absolute;
}

.work-links {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  margin: 5px 15px;
  overflow: hidden;
  min-height: 70px;
  background: rgba(20, 157, 221, 0.05);
  border-radius: 8px;
  padding: 8px 30px;
  border: 1px solid rgba(20, 157, 221, 0.1);
}

.work-link {
  background: none;
  border: none;
  color: #149ddd;
  font-size: 14px;
  text-align: center;
  padding: 0;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

.work-description {
  color: #a8a9b4;
  font-size: 12px;
  margin: 3px 0 0 0;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.divider {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 20px 0;
}

.sliding-panel .section-header {
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
  padding: 6px 8px;
  text-align: center !important;
  border-top: none;
  border-bottom: none;
  position: relative;
  z-index: 2;
  font-size: 13px;
}

.sliding-panel .section-header::after,
.sliding-panel .section-header::before {
  display: none;
  content: none;
}

.section-header .section-text {
  display: none;
}

.sliding-panel.expanded .section-header .section-text {
  display: inline;
}

.work-title,
.work-description {
  display: none;
}

.sliding-panel.expanded .work-title,
.sliding-panel.expanded .work-description {
  display: block;
}

.work-links {
  margin: 5px auto;
}

.sliding-panel:not(.expanded) .work-links,
.sliding-panel:not(.expanded) .divider,
.sliding-panel:not(.expanded) h3:not(.section-header) {
  display: none;
}

.sliding-panel.expanded .section-content {
  display: block;
}

@supports (-webkit-touch-callout: none) {
  .sliding-panel {
    height: -webkit-fill-available;
  }
  
  .panel-content {
    min-height: -webkit-fill-available;
  }
}

@media (max-height: 600px) {
  .profile {
    padding: 20px 0 0 0;
  }
  
  .sliding-panel:not(.expanded) .profile {
    padding: 30px 0;
  }
  
  .nav-menu {
    padding: 0;
    margin: 0;
  }

  .nav-menu a {
    padding: 6px 15px;
    margin-bottom: 2px;
  }

  .nav-menu > ul {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .sliding-panel {
    width: 60px;
  }
  
  .sliding-panel.expanded {
    width: 280px;
  }
  
  .sliding-panel.expanded .panel-toggle {
    left: 265px;
  }
  
  .panel-content {
    max-width: 100%;
  }
}

.main-content {
  height: 100vh;
  overflow-y: auto;
  position: relative;
}

.nav-link.active {
  color: #149ddd !important;
}

.nav-link.active i {
  color: #149ddd;
}

.nav-link.active .nav-text {
  color: #149ddd;
}

.section-header .bi-heart-fill {
  font-size: 22px;
  color: #149ddd;
}

@media (max-width: 768px) {
  .section-header .bi-heart-fill {
    font-size: 20px;
  }
}