.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 60px; /* Default collapsed panel width */
  transition: margin-left 0.3s ease;
  width: calc(100% - 60px); /* Account for panel width */
}

.content-wrapper {
  flex: 1;
  width: 100%;
  position: relative;
  padding-bottom: 40px;
}

/* When panel is expanded, push content */
.panel-expanded {
  margin-left: 300px; /* Match expanded panel width */
  width: calc(100% - 300px); /* Adjust width for expanded panel */
}

/* Home page specific styles */
.home-page .content-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.home-page #hero {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .main-content {
    margin-left: 60px; /* Keep collapsed panel width on mobile */
    width: calc(100% - 60px);
  }
  
  .panel-expanded {
    margin-left: 300px; /* Keep expanded width on mobile */
    width: calc(100% - 300px);
  }
} 